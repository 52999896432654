<template>
  <Contacts/>
</template>

<script>
import Contacts from "@/components/Contacts";

export default {
  name: "ContactPage",
  components: {Contacts}
}
</script>

<style scoped>

</style>