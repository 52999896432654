<template>
  <section class="Contacts">
    <div class="Container">
      <div class="Contacts-Wrapper">
        <Form />
        <div class="Contacts-Contacts">
          <h1 class="Title-h3 Contacts-ContactsTitle">Contact us</h1>
          <div class="Contacts-ContactsName">address</div>
          <div class="Contacts-ContactsText">
            Vasilissis Freiderikis, 34 Flat/Office 106
            1035, Nicosia, Cyprus
          </div>
          <div class="Contacts-ContactsName">phone</div>
          <a href="tel:+35725377077" class="Contacts-ContactsText">+35725377077</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import Form from "@/components/Form";

export default {

  name: "Contacts",
  components: {Form,},
  data() {
    return {
    }
  },

}
</script>

<style scoped lang="scss">
.Contacts {
  padding: 150px 0 100px;

  @media (min-width: $screen-l) {
    padding: 220px 0;
  }

  &-Wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: $screen-l) {
     flex-direction: row;
    }
  }

  &-Contacts {
    margin-top: 40px;
    padding-left: 22px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 3px;
      height: 201px;
      top: 0;
      left: 0;
      background-color: var(--color-text-yellow-dark);
    }
    @media (min-width: $screen-l) {
      width: 347px;
      margin-top: 0;
    }
  }

  &-ContactsTitle {
    margin-bottom: 50px;
  }

  &-ContactsName {
    margin-bottom: 6px;
    font-weight: 800;
    font-size: 11px;
    line-height: 1.23;
    text-transform: uppercase;
  }

  &-ContactsText {
    margin-bottom: 18px;
    font-size: 16px;
    line-height: 1.23;

  }
}

</style>