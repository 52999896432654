<template>
  <form class="Form" @submit.prevent="onSubmit()" novalidate>
    <div class="Form-Title">
      Feel free to contact us any time. We will get back to you as soon as wee can!
    </div>
    <div class="Form-Top">
      <div class="Form-Field">
        <input type="text"
               class="Form-Input Input"
               name="name"
               placeholder="Name"
               v-model.trim="form.name"
               :class="$v.form.name.$error ? 'is-invalid' : ''"
        >
        <span v-if="$v.form.name.$dirty && !$v.form.name.required"
              class="invalid-feedback">Required field!</span>

      </div>
      <div class="Form-Field">
        <input type="email"
               class="Form-Input Input"
               name="email"
               placeholder="Email"
               v-model.trim="form.email"
               :class="$v.form.email.$error ? 'is-invalid' : ''"
        >
        <span v-if="$v.form.email.$dirty && !$v.form.email.required"
              class="invalid-feedback">Required field!</span>
        <span v-if="$v.form.email.$dirty && !$v.form.email.email"
              class="invalid-feedback">Incorrect email</span>
      </div>
    </div>
    <div class="Form-Field">
              <textarea name="message"
                        class="Form-Textarea Input"
                        placeholder="Text"
                        v-model.trim="form.message"
                        :class="$v.form.message.$error ? 'is-invalid' : ''"
              ></textarea>

      <span v-if="$v.form.message.$dirty && !$v.form.message.required"
            class="invalid-feedback">Required field!</span>

    </div>
    <button type="submit" class="Btn Form-Btn">send</button>
  </form>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {email, required} from "vuelidate/lib/validators";
import axios from 'axios';
import Modal from "@/components/Modal";

export default {
  mixins: [validationMixin],
  name: "Form",
  data() {
    return {
      form: {
        name: '',
        email: '',
        message: '',
      },
    }
  },
  validations: {
    form: {
      name: {
        required,
      },
      email: {
        required,
        email
      },
      message: {
        required,
      },
    }
  },
  methods: {
    showAuthDialog() {
      this.$modal.show(
          Modal,
          {},
          {
            width: 428,
            height: 'auto',
            adaptive: true,
            scrollable: true,
            style: "{ backgroundColor: 'rgba(1,7,11,.7)'}",
            clickToClose: false,
          },
          {},
      );
    },
    onSubmit() {
      this.$v.form.$touch();
      if (!this.$v.form.$error) {
        const params = new URLSearchParams();
        params.append('name', this.form.name);
        params.append('email', this.form.email);
        params.append('message', this.form.message);

        axios.post(
            "/mail.php",
            params,
            {
              headers: {
                'content-type': 'application/x-www-form-urlencoded'
              }
            }
        ).then(() => {

        })
        this.showAuthDialog()
        this.form.name = ''
        this.form.email = ''
        this.form.message = ''
        this.$nextTick(() => {
          this.$v.$reset()
        })
      }
    },
  }
}
</script>

<style scoped lang="scss">

.Form {
  max-width: 635px;
  width: 100%;
  padding: 40px 23px;
  background-color: var(--color-text-main2);

  @media (min-width: $screen-l) {
    padding: 43px 69px 57px 65px;
  }

  &-Title {
    margin-bottom: 28px;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.5;
    color: var(--color-text-main3);
  }

  &-Top {
    margin-bottom: 24px;
    @media (min-width: $screen-l) {
      display: flex;
      align-items: center;
    }
  }

  &-Field {
    width: 100%;
    max-width: 100%;
    margin-bottom: 24px;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    @media (min-width: $screen-l) {
      margin-right: 10px;
      margin-bottom: 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .Input {
    max-width: 100%;
    width: 100%;
    border-bottom: 1px solid var(--color-border);
    border-top: 1px solid var(--color-text-main2);
    border-right: 1px solid var(--color-text-main2);
    border-left: 1px solid var(--color-text-main2);
    background-color: var(--color-text-main2);
    outline: none;
    resize: none;
    font-size: 16px;
    line-height: 1.23;
    color: var(--color-text-main3);

    &::placeholder {
      color: var(--color-text-placeholder);
    }

    &:focus {
      border-top: 1px solid var(--color-border);
      border-right: 1px solid var(--color-border);
      border-left: 1px solid var(--color-border);
    }
  }

  &-Input {
    padding: 19px;
  }

  &-Textarea {
    height: 63px;
    padding: 24px 19px 17px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;

    &::placeholder {
      font-weight: 400;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &-Btn {
    display: block;
    width: 100%;
    margin: 47px auto 0;
    padding: 24px 24px 25px;
    border-radius: 4px;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    color: var(--color-text-main3);
    background-color: var(--color-text-yellow-light);
    transition: .3s;

    &:hover {
      background-color: var(--color-text-yellow-dark);
    }
  }

  .invalid-feedback {
    position: absolute;
    top: -15px;
    right: 0;
    font-size: 12px;
    color: var(--color-text-error);
  }
}
</style>